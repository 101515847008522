// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

const application = Application.start(document.documentElement, {
  controllerAttribute: 'data-controller',
  actionAttribute: 'data-st-action',
  targetAttribute: 'data-target',
  targetAttributeForScope: identifier => `data-${identifier}-target`
});

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

import consumer from '../channels/consumer'
application.consumer = consumer
