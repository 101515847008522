import CableReady from 'cable_ready'

CableReady.DOMOperations.appendMessage = detail => {
  const { element, html, readUpto, messageId } = detail;

  const $element = $(element);
  $element.append(html);

  window.scrollChat($element);

  if (messageId && (readUpto === undefined || readUpto)) {
    const chat = $element.parents('.support-chat')[0];
    if (chat && chat.supportChat) chat.supportChat.readUpto(messageId);
  }
}
