
import {Controller} from 'stimulus'
import CableReady from 'cable_ready'
import _ from 'lodash'

export default class extends Controller {
  static targets = ['message', 'sendButton'];
  static values = {id: Number, type: String};

  connect () {
    this.element[_.camelCase(this.identifier)] = this;

    const $messageTarget = $(this.messageTarget);

    window.scrollChat($(this.element).find('.chat-messages'));

    this.channel = this.application.consumer.subscriptions.create(
        {
          channel: 'SupportChatChannel',
          type: this.typeValue,
          id: this.idValue
        }, {
          // Called when the subscription is ready for use on the server
          connected() {
            $messageTarget.attr('disabled', false)
            $messageTarget.attr('placeholder', 'Введите сообщение')
          },

          // Called when the subscription has been terminated by the server
          disconnected() {
            $messageTarget.attr('disabled', true)
            $messageTarget.attr('placeholder', 'Отключено от сервера')
          },

          // Called when the subscription has been rejected by the server
          rejected() {
            $messageTarget.attr('disabled', true)
            $messageTarget.attr('placeholder', 'Не подключено к серверу')
          },

          // Called when there's incoming data on the websocket for this channel
          received(data) {
            if (data.cableReady) CableReady.perform(data.operations, {emitMissingElementWarnings: window.emitMissingElementWarnings})
          },

          sendMessage(message) {
            this.perform('send_message', {id: this.idValue, message: message})
          },

          readUpto(messageId) {
            this.perform('read_upto', {id: this.idValue, message_id: messageId})
          }
        });
  }

  sendMessage() {
    if (this.lastSentAt && (this.lastSentAt + 2000) >= Date.now()) return;
    const element = this.messageTarget;
    const trim_val = element.value.trim()
    if (trim_val !== '') {
      this.channel.sendMessage(trim_val)
      element.value = '';
      this.lastSentAt = Date.now();

      const $sendButton = $(this.sendButtonTarget);
      $sendButton.addClass('text-muted');

      setTimeout(function() {$sendButton.removeClass('text-muted')}, 2000);
    }
  }

  keyDown(event) {
    if ((event.keyCode == 10 || event.keyCode == 13) && !event.repeat && !event.ctrlKey && !event.shiftKey) {
      this.sendMessage();
      event.preventDefault();
    }
  }

  disconnect () {
    this.channel.unsubscribe()
  }

  readUpto(messageId) {
    this.channel.readUpto(messageId)
  }
}