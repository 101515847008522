import 'toastr/toastr.scss'

import './src/web/assets/mobirise-icons2/mobirise2.css'
import './src/web/assets/mobirise-icons/mobirise-icons.css'
import './src/bootstrap/css/bootstrap.min.css'
import './src/bootstrap/css/bootstrap-grid.min.css'
import './src/bootstrap/css/bootstrap-reboot.min.css'
import './src/dropdown/css/style.css'
import './src/tether/tether.min.css'
import './src/theme/css/style.css'
import './src/mobirise/css/mbr-additional.scss'

import './landing.scss'
import './../smart_admin/src/custom/plugins/toastr/toastr-custom.scss'

import './src/popper/popper.min'

// import './src/web/assets/jquery/jquery.min'
import 'jquery'
import 'jquery-debounce-throttle'

import './src/bootstrap/js/bootstrap.min'
import './src/dropdown/js/nav-dropdown'
import './src/dropdown/js/navbar-dropdown'
import './src/touchswipe/jquery.touch-swipe.min'
import './src/parallax/jarallax.min'
import './src/tether/tether.min'
import './src/smoothscroll/smooth-scroll'
import './src/theme/js/script'
import './../smart_admin/src/scss/app.icons.scss'