
$(document).ready(function () {
  $('#org_tab').on('keyup blur', '.inn', $.debounce(function () {
    const inn = this.value;
    if (inn && inn.length === 10) {
      $('.spinner')
          .prop('title', 'Получение информации об организации')
          .removeClass('d-none');
      $('.error')
          .addClass('d-none')
          .prop('title', '');

      $.get(
          '/orgs/info.json',
          {inn: inn},
          function(data) {
            if (data.msg) {
              $('.error').prop('title', data.msg).removeClass('d-none');
              $('.forward_button').attr('disabled', true);
            } else {
              $('#org_ogrn').html(`ОГРН ${data.ogrn}`);
              $('#org_kpp').html(`КПП ${data.kpp}`);
              $('#org_title').html(data.title);
              if (data.title !== data.short_title) {
                $('#org_short_title').html(data.short_title);
              } else {
                $('#org_short_title').html('');
              }
              $('#org_address').html(data.address);

              $('#user_position').html(`${data.position}`);
              $('#user_last_name').html(` - ${data.last_name}`);
              $('#user_first_name').html(` ${data.first_name}`);
              $('#user_middle_name').html(` ${data.middle_name}`);

              $('.forward_button').attr('disabled', false);
            }

            $('.spinner').addClass('d-none').prop('title', '');
            $('.invalid-feedback').remove();
            $('.inn').removeClass('is-invalid');
          }
      )
    } else {
      $('.spinner').addClass('d-none').prop('title', '');
      $('.error').addClass('d-none').prop('title', '');
      $('.forward_button').attr('disabled', true);
    }
  }, 1500));
});
