import CableReady from 'cable_ready'
import consumer from "./consumer"

consumer.subscriptions.create('CountsChannel', {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.cableReady) CableReady.perform(data.operations, {emitMissingElementWarnings: window.emitMissingElementWarnings})
  }
});
