$(function () {
  $('.carousel').carousel({
    interval: false
  })
})

$(document).on('change', 'input[name="accept_privacy"]', function() {
  const $this = $(this);
  const checked = $this.is(':checked')

  $('.sign-in-button').attr('disabled', !checked);
});

$(document).on('click', '.read-more-btn', function() {
  const $this = $(this);
  const readLessBtn = $this.parent().children('.read-less-btn')
  const text = $this.parent().children('#news-content')

  text.removeClass('news-card-content')
  $this.addClass('d-none')
  readLessBtn.removeClass('d-none')
});

$(document).on('click', '.read-less-btn', function() {
  const $this = $(this);
  const readMoreBtn = $this.parent().children('.read-more-btn')
  const text = $this.parent().children('#news-content')

  text.addClass('news-card-content')
  $this.addClass('d-none')
  readMoreBtn.removeClass('d-none')
});

$(document).on("click", ".scale-img-btn", function (e) {
  const imgToScale = $(this).parent()
  imgToScale.addClass('img-scaled')
  $(this).addClass('d-none')
})

$(document).on("click", ".img-scaled", function (e) {
  const scaleBtn = $(this).children('.scale-img-btn')
  $(this).removeClass('img-scaled')
  scaleBtn.removeClass('d-none')
})

window.jumpTo = function (anchor, url = '') {
  if (url) {
    const location = `${url}#${anchor}`
    const currentLocation = window.location.pathname
    if (!location.includes(currentLocation))
    {
      window.location = location
      return
    }
  }
  var top = document.getElementById(anchor).offsetTop
  window.scrollTo({
    top,
    behavior: 'smooth'
  })
}

